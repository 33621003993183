import { Script } from "gatsby";
import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_PAYMENT_DATA } from "../../../../common/data/SecD3v";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  AboutTitle,
  ContactDetails,
  ColumnContents,
} from "./section.style";

const Section = () => {
  const { sections } = SECTION_PAYMENT_DATA;
  const [invoiceNumber, setInvoiceNumber] = useState("inv-");
  const [amount, setAmount] = useState("");
  const [fee, setFee] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [invoiceDescription, setInvoiceDescription] = useState("");
  let temp = "";

  const [shown, setShown] = useState(false);
  const addScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
  };

  // Use this if not using onsubmit
  // useEffect(() => {
  //   const addScript = (url) => {
  //     const script = document.createElement("script");
  //     script.src = url;
  //     script.async = true;
  //     document.body.appendChild(script);
  //   };
  // }, []);

  const handleSubmit = (event) => {
    event.preventDefault(); // 👈️ prevent page refresh

    // 👇️ access input values here
    console.log("invoiceNumber 👉️", invoiceNumber);
    console.log("amount 👉️", amount);

    // 👇️ clear all input values in the form
    // setInvoiceNumber("");
    // setAmount("");
    setInvoiceDescription("Payment for " + invoiceNumber);
    setShown(!shown);
    addScript("https://www.simplify.com/commerce/simplify.pay.js");
  };

  return (
    <>
      <SectionArea id="payments">
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections.about_us} />
              <Text as="p" content={sections.about_linetag} />
              {!shown ? (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="labelForm" htmlFor="invoice_label">
                      Invoice Number:
                    </label>
                    <input
                      type="text"
                      name="invoice"
                      className="form-control"
                      id="invoice"
                      placeholder="Enter invoice number"
                      required="required"
                      onChange={(event) => {
                        console.log(event.target.value);
                        temp = event.target.value;
                        setInvoiceNumber("");
                        setInvoiceNumber(temp);
                        if (invoiceNumber === "") {
                          setInvoiceNumber("inv-");
                        }
                      }}
                      value={invoiceNumber}
                    />
                  </div>
                  <div className="form-group">
                    <label className="labelForm" htmlFor="amount_label">
                      Amount (AUD):
                    </label>
                    <input
                      type="number"
                      name="amount"
                      className="form-control"
                      id="amount"
                      placeholder="Enter amount"
                      required="required"
                      onChange={(event) => {
                        setAmount(+event.target.value);
                        setSubtotal(+event.target.value);
                        setFee((event.target.value * 1.26) / 100);
                        setTotal(
                          +event.target.value +
                            (event.target.value * 1.26) / 100
                        );
                      }}
                      value={amount}
                    />
                  </div>
                  <div className="feeTotal">
                    {"Fee 1.26%: $" +
                      new Intl.NumberFormat("en-AU").format(fee)}
                  </div>
                  <div className="subTotal">
                    {"Subtotal: $" +
                      new Intl.NumberFormat("en-AU").format(subtotal)}
                  </div>
                  <div className="payTotal">
                    {"Total: $" + new Intl.NumberFormat("en-AU").format(total)}
                  </div>
                  <button name="submit" type="submit" className="Button">
                    Proceed
                  </button>
                </form>
              ) : null}

              {shown ? (
                <>
                  <iframe
                    className="paymentContainer"
                    name="secd3v-form"
                    data-sc-key="lvpb_OTI0ZmZhYTAtMTA0Zi00YTkyLTgwNDktMjY4MjY0ODE2Njk3"
                    data-name={invoiceDescription}
                    data-description={invoiceDescription}
                    data-reference={invoiceNumber}
                    data-amount={total * 100}
                    data-color="#132636"
                    data-receipt="true"
                    data-feeestimated="true"
                  ></iframe>
                </>
              ) : null}
            </Box>
            <Box className="blockTitle">
              <img
                className="imageBlockAbout"
                src={sections.imageAbout}
                alt=""
              />
            </Box>
          </Row>
        </Container>
      </SectionArea>
    </>
  );
};

export default Section;
