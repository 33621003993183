import Sticky from "react-stickynode";
import Navbar from "../containers/SecD3v/Navbar";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import SEO from "../components/SEO";
import Banner from "../containers/SecD3v/Payments/Banner";
import Section from "../containers/SecD3v/Payments/Section";
import Footer from "../containers/SecD3v/Footer";

import React from "react";

const Payments = () => {
  return (
    <>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar />
        </DrawerProvider>
      </Sticky>
      <SEO title="Payments" />
      <Banner />
      <Section />
      <Footer />
    </>
  );
};

export default Payments;
