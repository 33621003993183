import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

const SectionArea = styled.section`
  padding-top: 150px;
  padding-bottom: 150px;
  @media (max-width: 1600px) {
    padding-top: 100px;
    padding-bottom: 115px;
  }
  @media (max-width: 375px) {
    padding-top: 70px;
    padding-bottom: 95px;
  }
  .imageBlock {
    width: 50px;
    float: left;
    margin-right: 10px;
  }
  .imageBlockAbout {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .blockTitle {
    flex: 0 0 50%;
    @media (max-width: 768px) {
      flex: 0 0 100%;
      padding-top: 50px;
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
    @media (max-width: 480px) {
      text-align: left;
    }
    h2 {
      font-weight: 500;
      font-size: 40px;
      line-height: 1.37;
      letter-spacing: -0.02em;
      color: #132636;
      margin: 0;
      max-width: 505px;
      @media (max-width: 1600px) {
        font-size: 32px;
        line-height: 1.41;
        max-width: 413px;
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 1.37;
      letter-spacing: -0.02em;
      color: white;
      margin: 0;
      max-width: 505px;
      @media (max-width: 1600px) {
        font-size: 32px;
        line-height: 1.41;
        max-width: 413px;
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
    p {
      margin: 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 2;
      color: #132636;
      max-width: 553px;
      margin-top: 30px;
      margin-bottom: 30px;
      @media (max-width: 1600px) {
        font-size: 15px;
        line-height: 1.87;
        max-width: 465px;
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      font-weight: 700;
      color: #132636;
      font-size: 16px;
      position: relative;
      padding: 10.5px 29px;
      z-index: 1;
      &:hover {
        &:before {
          opacity: 0;
        }
        span {
          i {
            transform: translateX(2px);
          }
        }
      }
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        transition: opacity 0.4s ease;
      }
      &:before {
        background-image: linear-gradient(173.72deg, #fff 5%, #fff 190.2%);
      }
      &:after {
        background-image: linear-gradient(
          135.72deg,
          #ff8030 0%,
          #ff8030 130.2%
        );
        z-index: -1;
      }
      i {
        margin-left: 10px;
        position: relative;
        top: -1px;
        transition: transform 0.4s ease;
      }
      span {
        position: relative;
        display: flex;
        z-index: 2;
      }
    }
  }
  li::before {
    content: "•";
    color: #ff8030;
    margin-right: 10px;
  }
  .aboutList {
    color: white;
    margin-top: 20px;
  }
  .aboutListText {
    font-size: 15px;
    font-weight: 400;
  }
  .postsWrap {
    flex: 0 0 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 79%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  .postsWrap img {
    width: 400px;
  }

  .mapContainer {
    display: inline-block;
    border-radius: 24px;
    overflow: hidden;
    border: 4px solid #c8c8c8;
  }

  .aboutSection {
    background-color: #132636;
  }

  .post {
    width: calc(50% - 12.5px);
    padding: 50px 40px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(132, 159, 184, 0.15);
    border-radius: 10px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 425px) {
      padding: 30px;
      width: calc(50% - 12.5px);
    }
    p {
      margin: 0;
      font-size: 18px;
      color: #132636;
      line-height: 1;
      @media (max-width: 1600px) {
        font-size: 17px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
      }
    }
    @media (min-width: 769px) {
      &:nth-of-type(2) {
        position: relative;
        top: 40px;
      }
      &:nth-of-type(4) {
        position: relative;
        top: 40px;
      }
      &:nth-of-type(3) {
        .postCount {
          align-items: baseline;
          span {
            top: 0;
          }
        }
      }
    }
    transition: transform 0.4s ease;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .postCount {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
    h3 {
      margin: 0;
      font-size: 80px;
      line-height: 1;
      letter-spacing: -0.02em;
      color: #ff8030;
      font-weight: 400;
      @media (max-width: 1600px) {
        font-size: 70px;
      }
      @media (max-width: 768px) {
        font-size: 60px;
      }
      @media (max-width: 375px) {
        font-size: 48px;
      }
    }
    span {
      margin: 0;
      font-weight: 500;
      font-size: 40px;
      line-height: 1;
      letter-spacing: -0.02em;
      color: #ff8030;
      position: relative;
      top: 5px;
      margin-left: 5px;
      @media (max-width: 375px) {
        font-size: 30px;
      }
    }
  }
  .paymentContainer {
    width: 100%;
    height: 500px;
    border: none;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .labelForm {
    display: inline-block;
    width: 150px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }

  .form-control {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    padding: 12px;
    border-radius: 3px;
    width: 250px;
    font-size: 14px;
  }

  .Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-bottom: 50px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    line-height: 1;
    color: #ffffff;
    padding: 21px 29px;
    background-color: #132636;
    cursor: pointer;
    border: 0;
    transition: all 500ms ease;
    &:hover {
      background-color: grey;
      color: white;
      i {
        transform: translateX(2px);
      }
    }
    i {
      margin-left: 10px;
      position: relative;
      top: 1px;
      transition: transform 0.4s ease;
    }
    @media (max-width: 1600px) {
      font-size: 14px;
      padding: 16px 23px;
      margin-bottom: 0;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  .payTotal {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .subTotal {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 500;
  }
  .feeTotal {
    font-size: 15px;
    margin-top: 25px;
    font-weight: 500;
  }
`;
export default SectionArea;

export const ColumnContents = styled.div`
  background-color: #132636;
`;

export const TopHeading = styled.div`
  text-align: left;
  margin-bottom: 30px;
  h2 {
    font-weight: 500;
    font-size: 32px;
    text-align: left;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    color: #132636;
    @media (max-width: 1600px) {
      font-size: 32px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
    }
  }
`;
export const ContactDetails = styled.div`
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 2;
  color: white;
  max-width: 553px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1600px) {
    font-size: 15px;
    line-height: 1.87;
    max-width: 465px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
`;
export const Col = styled.div`
  flex: 0 0 33.33%;
  max-width: 33.33%;
  @media (max-width: 667px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
